<template>
  <base-section id="marketing">
    <v-container>
      <v-row>
        <v-col
          cols="2"
          class="text-center hidden-sm-and-down"
        >
          <template v-for="(card, i) in cards">
            <base-avatar-card
              :key="card.title"
              align="center"
              v-bind="card"
            />

            <v-divider
              v-if="i + 1 !== cards.length"
              :key="`divider-${i}`"
              class="my-8"
            />
          </template>
        </v-col>

        <v-col
          cols="2"
          class="hidden-sm-and-down text-center"
        >
          <v-responsive
            height="calc(100% - 16px)"
            class="mt-2"
          >
            <v-divider vertical />
          </v-responsive>
        </v-col>

        <v-col
          cols="12"
          md="7"
        >
          <base-subheading
            size="display-1"
            title="PRODUCT LIST"
            space="0"
            align="center"
          />
          <br>
          <v-row>
            <v-col
              v-for="(text, i) in products"
              :key="i"
              cols="12"
              md="6"
            >
              <base-list-item :text="text" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMarketing',

    data: () => ({
      cards: [
        {
          title: 'Our products',
          icon: 'mdi-briefcase-outline',
          outlined: true,
        },
      ],
      products: [
        'Activated Carbon (coconut shell)',
        'Antioxidant',
        'Antitack Powder Free',
        'Antifoam',
        'Acrylic Polymer Binder',
        'Biocides',
        'Coagulant',
        'Dispersing Agents',
        'Fragrance/Flavour',
        'Formic Acid',
        'Nitrile Butadiene Latex',
        'PVC Resin Paste',
        'Polyurethane Wet Donning',
        'Polyurethane Polymer',
        'Titanium Dioxide',
        'Wetting Agents',
        'Wetting Donning Agents',
      ],
    }),
  }
</script>
